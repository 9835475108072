<template>
  <div
      class="flex-row-fluid d-flex flex-column position-relative p-14 overflow-hidden"
  >
    <div class="d-flex flex-column-fluid flex-center">
      <div class="d-flex flex-column align-items-center align-center text-center ">

        <img src="media/misc/email.png" style="height: 200px"/>
        <h1 class="mb-5">Your pre-registration has been saved.</h1>
        <h3 class="mb-5">To resume the application, please check your email with link to application.</h3>
        <h5 class="mb-5 ">Please keep this number to make any further inquiry.</h5>
        <h3 class="font-weight-bold font-size-lg p-4 badge badge-primary">#{{ enrollment.enrollment_id }}</h3>
        <v-btn @click.prevent="newPreRegistration" class="text-black font-size-base">
          New Registration
        </v-btn>
        <a href="#" @click.prevent="$router.push({name:'index'})" class="text-black font-size-base">
          Visit Website
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Success",
  computed: {
    enrollment() {
      return this.$store.getters.getEnrollment
    }
  }, methods: {
    newPreRegistration() {
      window.location.href = '/pre-registration';
    }
  }
}
</script>

<style scoped>

</style>
